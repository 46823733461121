import Swiper, { Mousewheel, Autoplay } from 'swiper';

class HomepageSections {
  constructor() {
    this.sections = document.querySelector('.js-homepage-sections');

    if (!this.sections) {
      return;
    }

    this.selectors = {
      navContainer: '.js-homepage-nav-items',
      navItem: '.js-homepage-nav-item',
      navCurrent: '.js-homepage-nav-current',
      scrollButton: '.js-home-section-scroll',
      navLink: '.js-homepage-nav-link',
    };

    this.classes = {
      active: 'is-active',
      swiper: 'is-swiper',
      hasFooter: 'has-footer',
    };

    this.data = {
      speed: 1000,
    };

    this.breakpoint = window.matchMedia('(min-width: 1025px)');

    this.DOM = {
      navContainer: document.querySelector(this.selectors.navContainer),
      navItems: document.querySelectorAll(this.selectors.navItem),
      navCurrent: document.querySelector(this.selectors.navCurrent),
      scrollButton: document.querySelector(this.selectors.scrollButton),
    };

    document.addEventListener('DOMContentLoaded', () => {
      this.init();
      this.bindResizeEvent();
      this.navCurrentRePosition();
    });
  }

  bindResizeEvent() {
    const changeEvent = (event) => {
      if (event.matches) {
        this.init();
      } else {
        setTimeout(() => {
          this.destroy();
        }, 100);
      }
    };

    if (typeof this.breakpoint.addEventListener === 'undefined') {
      this.breakpoint.addListener(changeEvent);
    } else {
      this.breakpoint.addEventListener('change', changeEvent);
    }
  }

  navCurrentRePosition() {
    let debounce = null;
    window.addEventListener('resize', () => {
      clearTimeout(debounce);

      debounce = setTimeout(() => {
        const activeItem = this.DOM.navContainer.querySelector(
          `${this.selectors.navItem}.${this.classes.active}`,
        );
        this.DOM.navCurrent.style.top = `${activeItem.offsetTop + 21}px`;
      }, 300);
    });
  }

  init() {
    if (!this.breakpoint.matches) {
      return;
    }

    const use = [Mousewheel];

    const args = {
      direction: 'vertical',
      resistanceRatio: 0,
      speed: this.data.speed,
      simulateTouch: false,
      mousewheel: {
        releaseOnEdges: true,
        sensitivity: 4,
        thresholdDelta: 60,
      },
      on: {
        init: () => {
          this.navigationClickHandler();
          this.scrollButtonHandler();
          this.setActiveItem();
          this.sections.classList.add(this.classes.swiper);
        },
        slideChange: (swiper) => {
          const { activeIndex } = swiper;
          this.newActiveItemIndex = activeIndex;
          this.setActiveItem();

          document.body.classList.toggle(
            this.classes.hasFooter,
            swiper.slides.length === activeIndex + 1,
          );
        },
      },
    };

    if (this.sections.getAttribute('data-swiper-autoplay') === 'yes') {
      use.push(Autoplay);
      args.autoplay = {
        delay:
          this.sections.getAttribute('data-swiper-autoplay-timeout') || 5000,
        disableOnInteraction: false,
      };
    }

    Swiper.use(use);

    this.swiper = new Swiper(this.sections, args);
  }

  navigationClickHandler() {
    this.DOM.navItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();

        if (!this.breakpoint.matches) {
          return;
        }

        this.newActiveItemIndex = [...item.parentNode.children].indexOf(item);
        this.setActiveItem();
        this.swiper.slideTo(this.newActiveItemIndex, this.data.speed);
      });
    });
  }

  setActiveItem() {
    const activeItem = this.DOM.navContainer.querySelector(
      `${this.selectors.navItem}.${this.classes.active}`,
    );

    const activeItemIndex = [...activeItem.parentNode.children].indexOf(
      activeItem,
    );

    if (activeItemIndex === this.newActiveItemIndex) {
      return;
    }

    const newActiveItem = this.DOM.navItems.item(this.newActiveItemIndex);

    if (newActiveItem) {
      activeItem.classList.remove(this.classes.active);
      newActiveItem.classList.add(this.classes.active);
      this.DOM.navCurrent.style.top = `${newActiveItem.offsetTop + 21}px`;
    }
  }

  destroy() {
    this.swiper.destroy(true, true);
    this.sections.classList.remove(this.classes.swiper);

    if (this.DOM.navItems.length > 1) {
      const activeItem = this.DOM.navContainer.querySelector(
        `${this.selectors.navItem}.${this.classes.active}`,
      );
      const newActiveItem = this.DOM.navItems[0];

      if (activeItem.getAttribute('id') !== newActiveItem.getAttribute('id')) {
        this.newActiveItemIndex = 0;
        activeItem.classList.remove(this.classes.active);
        newActiveItem.classList.add(this.classes.active);
        this.DOM.navCurrent.style.top = `${newActiveItem.offsetTop + 21}px`;
      }
    }
  }

  scrollButtonHandler() {
    if (this.DOM.navItems.length > 1) {
      this.DOM.scrollButton.addEventListener('click', () => {
        if (!this.breakpoint.matches) {
          return;
        }
        this.DOM.navItems[1].querySelector(this.selectors.navLink).click();
      });
    }
  }
}

export default new HomepageSections();
