class MainNav {
  constructor() {
    this.selectors = {
      nav: '.js-main-nav',
      navLink: '.js-main-nav-link, .js-subpages-nav-link',
      navToggler: '.js-main-nav-toggler',
    };

    this.mainNav = document.querySelector(this.selectors.nav);

    if (!this.mainNav) {
      return;
    }

    this.window = window;
    this.toggler = this.mainNav.querySelector(this.selectors.navToggler);

    this.classes = {
      open: 'is-open',
      active: 'is-active',
    };

    this.toggleNavHandler();
    this.activePage();
  }

  toggleNavHandler() {
    this.toggler.addEventListener('click', () => {
      this.mainNav.classList.toggle(this.classes.open);
      this.toggler.classList.toggle(this.classes.active);
    });
  }

  activePage() {
    const paths = this.window.location.pathname.split('/');

    if (paths.length) {
      const pageUrl = paths[paths.length - 1];
      const links = document.querySelectorAll(this.selectors.navLink);

      if (links.length) {
        const active = Array.from(links).filter((link) => {
          return link.getAttribute('href') === pageUrl;
        });

        if (active.length) {
          active.forEach((activeLink) => {
            activeLink.classList.add('is-current');
          });
        }
      }
    }
  }
}

export default new MainNav();
